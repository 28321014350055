<template>
  <b-card-code no-body title="Withdraw List">
    <div class="dt_adv_search ml-1 mr-1">
        <div class="row">

         <div class="col-3">
              <select class="form-control form-control-sm" v-model="filter.status">
                <option value="">-- All Status --</option>
                <option value="pending">Pending</option>
                <option value="success">Success</option>
                <option value="failed">Failed/Rejected</option>
                <option value="canceled">Canceled by system</option>
              </select>
          </div>
          <div class="col-3"></div>

          <div class="col-md-6">
            <button class="btn btn-success btn-sm float-right" @click="exportWithdraw()">
              <feather-icon
                icon="DownloadCloudIcon"
              />
              Export Withdraw
            </button>
          </div>

        </div>

    </div>
    <b-modal no-close-on-backdrop id="detail-modal" title="Detail Withdraw">
      
      <table class="table table-hover" v-if="detail != null">
        <tr>
          <td class="ml-0 pl-0">User</td>
          <td v-if="detail.user != null">
            <strong>{{ detail.user.code }}</strong>
            <router-link :to="{ name: 'users.show', params: { id: detail.user.id } }">
              <feather-icon
                icon="ExternalLinkIcon"
              />
            </router-link>
          </td><td v-else>-</td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Commodity</td>
          <td>
            <strong>{{ detail.commodity.name }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Depository</td>
          <td>
            <strong>{{ detail.warehouse.name }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Amount</td>
          <td>
            <strong>{{ detail.amount_formatted }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Withdraw Attachment File</td>
          <td>
            <strong v-if="detail.file_attachment_url">
              <a v-bind:href="detail.file_attachment_url" target="_blank">
                Open File 
                <feather-icon
                  icon="ExternalLinkIcon"
                />
              </a>
            </strong><span v-else>-</span>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Withdraw Time</td>
          <td>
            <strong>{{ detail.withdraw_time }}</strong>
          </td>
        </tr>
        <tr>
          <td class="ml-0 pl-0">Status</td>
          <td>
            <span class="badge text-capitalize badge-pill" v-bind:class="getStatusColor(detail.status)">{{ detail.status }}</span>
          </td>
        </tr>
      </table>

      <template #modal-footer="{}" v-if="detail != null && detail.status == 'pending'">
        <b-button variant="danger" @click="rejectWithdraw(detail)">
          Reject Withdraw
        </b-button>
        <b-button variant="success" @click="approveWithdraw(detail)">
          Approve Withdraw
        </b-button>
      </template>
    </b-modal>
    <br>
    <div class="table-responsive-sm">
      <table class="table b-table table-fixed">
        <thead>
          <tr>
            <th>User</th>
            <th>Commodity</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Withdraw Time</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="isLoading">
            <td colspan="6" class="text-center">
              <b-spinner
                class="mb-1"
                variant="primary"
              /><br>
              Loading...
            </td>
          </tr>
          <tr v-else v-for="item in result.data" v-bind:key="item.id">
            <td v-if="item.user != null">
                <router-link :to="{ name: 'users.show', params: { id: item.user.id } }">
                    {{ item.user.code }}<br><small>{{ item.user.email }}</small>
                </router-link>
            </td>
            <td v-else>-</td>
            <td>
                {{ item.commodity.name }}
            </td>
            <td>{{ item.amount_formatted }}</td>
            <td class="text-capitalize">
                <span class="badge text-capitalize badge-pill" v-bind:class="getStatusColor(item.status)">{{ item.status }}</span>
            </td>
            <td>{{ item.withdraw_time }}</td>
            <td>
              <div class="btn-group" role="group">
                <button class="btn btn-info btn-sm" @click="detailWithdraw(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Detail Withdraw">
                  <feather-icon
                    icon="InfoIcon"
                  />
                </button>
                <button class="btn btn-success btn-sm" v-if="item.status == 'pending' && checkPermission('approve withdraw')" @click="approveWithdraw(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Approve">
                  <feather-icon
                    icon="CheckCircleIcon"
                  />
                </button>
                <button class="btn btn-danger btn-sm" v-if="item.status == 'pending' && checkPermission('reject withdraw')" @click="rejectWithdraw(item)" style="margin-left: 2px;" data-toggle="tooltip" data-placement="top" title="Reject Withdraw">
                  <feather-icon
                    icon="XCircleIcon"
                  />
                </button>
              </div>
            </td>
          </tr>
          <tr v-if="result.total == 0 && !isLoading">
            <td colspan="7" class="text-center">Data is empty.</td>
          </tr>
        </tbody>
      </table>

      <div class="m-1" v-if="result.total > 0">
        <div class="row">
          <div class="col">
            <small>Showing {{ result.from }} to {{ result.to }} from {{ result.total }}</small>
          </div>
          <div class="col">
            <pagination :data="result" @pagination-change-page="getData" :limit="4" align="right"></pagination>
          </div>
        </div>
      </div>
    </div>
  </b-card-code>
</template>

<script>
import _ from 'lodash'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { BCard, BCardText, BButton, BDropdown, BDropdownItem, BSpinner } from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'
import { EditIcon } from 'vue-feather-icons'
import FileSaver from 'file-saver'
import moment from 'moment'

export default {
  title () {
    return `Commodity Withdraw List`
  },
  components: {
    BCard,
    BButton,
    BCardText,
    BCardCode,
    BDropdown,
    BDropdownItem,
    EditIcon,
    BSpinner
  },
  setup(props) {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  data() {
    return {
      currentPage: 1,
      result: {},
      filter: {
        status: this.$route.query.status == null ? '' : this.$route.query.status,
        amount: '',
      },
      detail: null,
      isLoading: false,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function() {     
        this.getData();                                                                                                                                                                        
      }, 300),
      deep: true
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData(page = 1) {

      this.isLoading = true;
      this.currentPage = page;
      var queryParams = this.filter
      queryParams.page = page

      this.$http.get('/admin/commodity-withdraw', {
        params: queryParams
      })
      .then(response => {
        this.result = response.data.data
        this.isLoading = false;
      })

    },
    detailWithdraw(item) {
      this.detail = item;

      this.$bvModal.show('detail-modal')
    },
    exportWithdraw() {
      var queryParams = this.filter

      this.$http.get('/admin/commodity-withdraw/export/csv', {
        params: queryParams,
        responseType: 'blob'
      })
      .then(response => {        
        var timestamp = moment().format('YYYY:MM:DD:HH:mm:ss');
        var fileName = 'commodity-withdraw-' + timestamp  + '.csv';
        FileSaver.saveAs(response.data, fileName);
      })
    },
    approveWithdraw(item) {

      this.$swal({
        title: 'Are you sure?',
        text: "Are you sure want mark this withdraw as success?",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.post('/admin/commodity-withdraw/' + item.uuid + '/approve')
          .then(response => {
    
            this.getData(this.currentPage)
            this.$bvModal.hide('detail-modal')
            this.$swal({
              icon: 'success',
              title: 'Approved!',
              text: "Withdraw status changed to success.",
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
        }
      })

    },
    rejectWithdraw(item) {

      this.$swal({
        title: 'Are you sure?',
        text: "User's commodity balance will be refund to user",
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          
          this.$http.post('/admin/commodity-withdraw/' + item.uuid + '/reject')
          .then(response => {
    
            this.getData(this.currentPage)
            this.$bvModal.hide('detail-modal')
            this.$swal({
              icon: 'success',
              title: 'Rejected!',
              text: "Withdraw status changed to rejected/failed.",
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })

          })
        }
      })

      

    },
    getStatusColor(status) {
      switch (status) {
        case 'success':
          return 'badge-light-success';
          break;

        case 'expired':
          return 'badge-light-danger';
          break;

        case 'failed':
          return 'badge-light-danger';
          break;

        case 'canceled':
          return 'badge-light-danger';
          break;
      
        default:
          return 'badge-light-secondary';
          break;
      }
    }
  }
}
</script>